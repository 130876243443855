<template>
  <b-container fluid class="mb-5">
    <b-row>
      <b-col>
        <h4 class="mb-4">Pedido: {{ order.orderId }}</h4>
        <b-form>
          <b-card title="Situação do Pedido" class="mb-3">
            <b-table
              :fields="fieldsStatus"
              :items="order.statusHistory"
              bordered
              striped
              hover
            >
              <template #cell(date)="row">
                {{ new Date(row.item.createdAt).toLocaleString("pt-BR") }}
              </template>
            </b-table>
          </b-card>
          <b-card title="Endereço de Entrega" class="mb-3">
            <b-row>
              <b-col>
                <form-input
                  v-model="order.client.name"
                  label="Destinatário:"
                  icon="person"
                  disabled
                  copyable
                />
              </b-col>
              <b-col>
                <form-input
                  :value="maskCpfCnpj(order.client.cpfCnpj)"
                  label="Cpf / Cnpj:"
                  icon="person"
                  disabled
                  copyable
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <form-input
                  v-model="order.zipcode"
                  label="CEP:"
                  icon="mailbox"
                  disabled
                  copyable
                />
              </b-col>
              <b-col cols="9">
                <form-input
                  v-model="order.address"
                  label="Endereço:"
                  icon="house-door"
                  disabled
                  copyable
                />
              </b-col>
              <b-col>
                <form-input
                  v-model="order.number"
                  label="Número:"
                  icon="three-dots"
                  disabled
                  copyable
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <form-input
                  v-model="order.complement"
                  label="Complemento:"
                  icon="card-text"
                  disabled
                  copyable
                />
              </b-col>
              <b-col>
                <form-input
                  v-model="order.references"
                  label="Referência:"
                  icon="compass"
                  disabled
                  copyable
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <form-input
                  v-model="order.district"
                  label="Bairro:"
                  icon="signpost"
                  disabled
                  copyable
                />
              </b-col>
              <b-col>
                <form-input
                  v-model="order.city"
                  label="Cidade:"
                  icon="geo-alt"
                  disabled
                  copyable
                />
              </b-col>
              <b-col>
                <form-input
                  v-model="order.state"
                  label="UF"
                  icon="map"
                  disabled
                  copyable
                />
              </b-col>
            </b-row>
          </b-card>
          <b-card title="Transportadora" class="mb-3">
            <b-row>
              <b-col cols="3">
                <form-input
                  v-model="order.trackingInfo.name"
                  label="Transportadora:"
                  icon="truck"
                  disabled
                />
              </b-col>
              <b-col cols="3">
                <form-input
                  v-model="order.trackingInfo.code"
                  label="Código de rastreio:"
                  icon="signpost-split"
                  disabled
                  copyable
                />
              </b-col>
              <b-col>
                <form-input
                  v-model="order.trackingInfo.link"
                  label="Link de rastreio:"
                  icon="globe2"
                  disabled
                  copyable
                />
              </b-col>
            </b-row>
          </b-card>
          <b-card title="Produtos" class="mb-3">
            <b-table
              :fields="fieldsProduct"
              :items="order.orderProducts"
              bordered
              striped
              hover
            >
              <template #cell(index)="row">
                {{ row.index + 1 }}
              </template>
              <template #cell(image)="row">
                <image-fallback
                  :image="
                    row.item.product.image[0]
                      ? row.item.product.image[0].imgUrl
                      : ''
                  "
                  :alt="row.item.productCode"
                  :height="100"
                  :width="100"
                />
              </template>
              <template #cell(price)="row">
                {{
                  row.item.price.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  })
                }}
              </template>
              <template #cell(amount)="row">
                {{
                  row.item.price.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  })
                }}
              </template>
            </b-table>
          </b-card>
          <b-card title="Informações de Pagamento">
            <b-row>
              <b-col>
                <form-input
                  :value="
                    new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    }).format(order.amount)
                  "
                  label="Total dos Produtos:"
                  icon="cash"
                  disabled
                />
              </b-col>
              <b-col>
                <form-input
                  :value="
                    new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    }).format(order.trackingInfo.cost)
                  "
                  label="Total do Frete:"
                  icon="cash"
                  disabled
              /></b-col>
              <b-col>
                <form-input
                  :value="
                    new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    }).format(order.total)
                  "
                  label="Total Pago:"
                  icon="cash"
                  disabled
                />
              </b-col>
              <b-col>
                <form-input
                  v-model="order.paymentMethod"
                  label="Forma de Pagamento:"
                  icon="credit-card"
                  disabled
                />
              </b-col>
              <b-col>
                <form-input
                  :value="`${order.installments}x`"
                  label="Parcelas:"
                  icon="list-ol"
                  disabled
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <form-input
                  :value="order.mercadoPagoId"
                  label="Código do Pagamento:"
                  icon="view-list"
                  disabled
                  copyable
                />
              </b-col>
              <b-col>
                <form-input
                  :value="order.movement"
                  label="Referência Externa do Mercado Pago:"
                  icon="stickies"
                  disabled
                  copyable
                />
              </b-col>
            </b-row>
          </b-card>
        </b-form>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import FormInput from "../../components/form/FormInput.vue";
import ImageFallback from "../../components/shared/ImageFallback.vue";
import { maskBr } from "js-brasil";

export default {
  components: { ImageFallback, FormInput },
  name: "detailsOrder",
  data() {
    return {
      fieldsProduct: [
        {
          key: "index",
          label: "N°",
          sortable: true,
        },
        {
          key: "image",
          label: "Imagem",
        },
        {
          key: "productCode",
          label: "Código",
          sortable: true,
        },
        {
          key: "completeDescription",
          label: "Descrição Completa",
          sortable: true,
        },
        {
          key: "quantity",
          label: "Quantidade",
          sortable: true,
        },
        {
          key: "price",
          label: "Valor Unitario",
          sortable: true,
        },

        {
          key: "amount",
          label: "Valor Total",
          sortable: true,
        },
      ],
      fieldsStatus: [
        {
          key: "date",
          label: "Data",
          sortable: true,
        },
        {
          key: "status.description",
          label: "Situação",
          sortable: true,
        },
      ],
    };
  },
  computed: {
    ...mapState("orders", ["order"]),
  },
  methods: {
    ...mapActions("orders", ["getOneOrder"]),
    ...mapMutations(["setOverlay"]),
    maskCpfCnpj(cpfcnpj) {
      return cpfcnpj.length === 11 ? maskBr.cpf(cpfcnpj) : maskBr.cnpj(cpfcnpj);
    },
  },
  async created() {
    this.setOverlay(true);
    if (!this.order.orderId) {
      const id = this.$route.params.id;
      await this.getOneOrder(id);
    }
    this.setOverlay(false);
  },
};
</script>

<style></style>
