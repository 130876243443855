<template>
  <div class="mb-3">
    <b-img
      v-if="!imageError"
      v-bind="mainProps"
      :src="image"
      :alt="alt"
      :title="alt"
      fluid
      @error="onError"
    />
    <b-img
      v-else
      v-bind="mainProps"
      src="../../assets/not-found-image.png"
      :alt="alt"
      fluid
    />
  </div>
</template>

<script>
export default {
  name: "ImageFallback",
  props: {
    image: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
      required: false,
    },
    width: {
      type: Number,
      default: 800,
      required: false,
    },
    height: {
      type: Number,
      default: 800,
      required: false,
    },
  },
  data() {
    return {
      imageError: false,
      mainProps: {
        width: this.width,
        height: this.height,
      },
    };
  },
  methods: {
    onError() {
      this.imageError = true;
    },
  },
};
</script>

<style></style>
